import React from "react";
import Icon from "@mdi/react";
import { mdiMenu } from "@mdi/js";

interface Props {
  children: object;
  noHeader?: boolean;
  firstPage?: boolean;
  btnBack?: boolean;
  title?: string;
  handleToggleSidebar: (toggled: boolean) => void;
}
const MainBody: React.FC<Props> = ({
  children,
  handleToggleSidebar = () => { },
  noHeader = true,
  firstPage = true,
  btnBack = false,
  title = "",
}) => {
  return (
    <main>
      <div>
        <div className="btn-toggle" onClick={() => handleToggleSidebar(true)}>
          <Icon path={mdiMenu} size="30" />
        </div>
      </div>

      <div className={`${noHeader || firstPage ? "body2" : "body1"}`} style={{ backgroundColor: '#f4f4f4' }}>
        {children}
      </div>
    </main>
  );
};

export default MainBody;
