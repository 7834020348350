import styled from 'styled-components';

const Container = styled.div`
    position: relative;
    padding: 2rem;
    div{
        background: #fff;
        padding: 0.5rem;
        border-radius: 5rem;
        display: flex;
        align-items: center;
        font-size: 1rem;
        @media screen and (max-width: 375px) {
            font-size: 0.8rem;
        }
        .logo{
            width: 30%;
            height: 80px;
            border-radius: 50%;
            background: #d7d7d7;
        }
        .content{
            width: 80%;
            padding-left: 0.5rem;
            display: grid;
            line-height: 0.7;
        }
    }
`
const ContainerRow = styled.div`
position: relative;
padding: 0.5rem 1.5rem;
`
const ContainerBox = styled.div`
background: #fff;
padding: 1rem;
border-radius: 1rem;
`
const BoxEnterCode = styled.div`
    background: #fff;
    padding: 1rem;
    border-radius: 1rem;
    .code{
        font-size: 1.2rem;
        font-weight: bold;
        @media screen and (max-width: 375px) {
            font-size: 1rem;
        }
    }
    input{
        width: 100%;
        background: #b3b3b3;
        border: 0;
        height: 40px;
        margin: 0.5rem 0;
    }
    button{
        width: 100%;
        background: #000;
        color: #fff;
        border: 0;
        padding: 0.6rem;
        border-radius: 0.5rem;
    }
    `
const TextTileH1 = styled.div`
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
    margin-top: 1rem;
    @media screen and (max-width: 375px) {
        font-size: 1rem;
    }
`
const TextTile = styled.div`
    font-size: 1.2rem;
    font-weight: bold;
    @media screen and (max-width: 375px) {
        font-size: 1rem;
    }
`

const BoxAlert = styled.div`
background: #fff;
padding: 1rem;
border-radius: 1rem;
border: 0.1rem solid #e7e7e7;
margin-bottom: 1rem;
.time{
    text-align: end;
    font-weight: bold;
    font-size: 0.6rem;
}
`
const ContainerFooter = styled.div`
    position: fixed;
    width: 100%;
    bottom: 0;
    background: #FDF004;
    padding: 1rem;
    border-radius: 3rem 3rem 0 0;
    div{
        position: relative;
        display: flex;
        align-items: center;
        text-align: center;
        font-size: 1rem;
        font-weight: bold;
        border-radius: 5rem;
        background: #fff;
        padding: 0.5rem;
        @media screen and (max-width: 375px) {
            font-size: 0.7rem;
            padding: 0.3rem;
        }
        .activity{
            color : #FFC700;
        }
    }
`
const BTNLine = styled.button`
padding: 0.5rem 2rem;
font-size: 1.5rem;
margin: 0.5rem;
color: #fff;
background: #20C92F;
border: 0.1rem solid #000;
border-radius: 0.5rem;
`
const BTNFB = styled.button`
padding: 0.5rem 2rem;
font-size: 1.5rem;
margin: 0.5rem;
color: #fff;
background: #4667AC;
border: 0.1rem solid #000;
border-radius: 0.5rem;
`
export { ContainerRow, ContainerBox, Container, ContainerFooter, BoxEnterCode, TextTile, BoxAlert, TextTileH1, BTNLine, BTNFB }