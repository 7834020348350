import { createAction } from "@reduxjs/toolkit";
interface Loader {
    isFetchedUser : boolean;
}


export const isFetchedUser = createAction("isFetchedUser", function prepare(
    loader: Loader
) {
    return {
        payload: loader
    };
});
