
import axios from "axios";
const setBearerToken = (token) => {
  if (!token) token = localStorage.getItem("token");

  axios.interceptors.request.use(function (config) {
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  });

  console.log("token setted...", token);

  const getToken = localStorage.getItem("token");
  console.log("getToken =>", getToken);
};

export { axios, setBearerToken };

