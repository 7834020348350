import React, { useState, useEffect } from "react";
import SideBarNav from "../sideBar/sideBarNav";
import Loading from "../Loading";
import MainBody from "./mainBody";
import MainFooter from "./mainFooter";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

interface Props {
  children: object;
  noHeader?: boolean;
  firstPage?: boolean;
  title?: string;
  btnBack?: boolean;
}
const Layout: React.FC<Props> = ({
  children,
  noHeader = false,
  firstPage = false,
  btnBack = false,
  title = "",
}) => {
  const { loader, isFetchedUser } = useSelector((state: any) => state);
  console.log(useSelector((state: any) => state))
  const history = useHistory();

  const [toggled, setToggled] = useState(false);

  const handleToggleSidebar = (value: boolean) => {
    setToggled(value);
  };


  useEffect(() => {
    if (loader.isFetchedUser) {
      console.log(isFetchedUser)
    }else{
      history.push(`/`);
    }
  }, [loader.isFetchedUser, history, isFetchedUser]);

  return (
    <>
      {!loader.isFetchedUser ? (
        <>
          <Loading />
        </>
      ) : (
        <>
          <div className={`app ${toggled ? "toggled" : ""}`}>
            <SideBarNav
              toggled={toggled}
              handleToggleSidebar={handleToggleSidebar}
            />
            <MainBody
              title={title}
              noHeader={noHeader}
              firstPage={firstPage}
              btnBack={btnBack}
              handleToggleSidebar={handleToggleSidebar}
            >
              {children}

              <MainFooter />
            </MainBody>
          </div>
        </>
      )}
    </>
  );
};

export default Layout;
