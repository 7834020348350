import HeaderBar from "../../components/header/headerBar";
import { useParams } from "react-router";
import { Table, Tag, Row, Col, Calendar, Divider } from "antd";
import type { ColumnsType } from 'antd/es/table';
import type { CalendarMode } from 'antd/es/calendar/generateCalendar';
import type { Moment } from 'moment';
import { EyeOutlined, HomeOutlined } from '@ant-design/icons';
import { Icon } from "@iconify/react";
import { useEffect, useState } from "react";
import axios from "axios";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet";
import 'leaflet/dist/leaflet.css';
import styled from "styled-components"
import GoogleMapReact from 'google-map-react';
import moment from "moment";
import environment from "../../config/environment";
const BoatIcon = L.icon({
  iconUrl: require('../../assets/images/smartphone.png').default,
  iconRetinaUrl: require('../../assets/images/smartphone.png').default,
  iconSize: [40, 40],
  iconAnchor: [12, 12],
  popupAnchor: [0, 0],
});
interface ParamTypes {
  id: string;
}
const PositionPage = () => {
  const [value, setValue] = useState(() => moment());
  const { id } = useParams<ParamTypes>();
  const [data, setData] = useState<any>([])
  const [makker, setMakker] = useState<any>([])
  const [select, setSelect] = useState<Moment>();
  const [detail, setDetail] = useState("");
  const columns: ColumnsType<any> = [
    {
      title: 'ช่วงเวลา',
      dataIndex: 'key',
      key: 'key',
      width: "20%",
      render: (text, object, index) => <>{moment(object?.created_at).format('MMMM Do YYYY, h:mm:ss a')}</>,
    },
    {
      title: 'ตำแหน่ง',
      dataIndex: 'members',
      key: 'members',
      width: "70%",
      render: (item, record) => {
        return (<div style={{ maxWidth: "500px", overflow: "auto" }}>Lat:{record?.lat} Long:{record?.long}
          <Divider />
          <Tag icon={<HomeOutlined />} color="#55acee">
            {record?.detail}
          </Tag>
        </div>)
      }
    },
    {
      title: '',
      key: 'tags',
      dataIndex: 'tags',
      align: "center" as "center",
      width: "10%",
      render: (text, object) => (
        <Row gutter={16}>
          <Col><EyeOutlined onClick={() => {
            setSelect(moment(object.created_at));
            setMakker([object?.lat, object?.long]);
            setDetail(object?.detail);
          }} /></Col>
        </Row>
      ),
    }
  ];
  const fetchMembers = (date = moment()) => {
    const url = `${environment.phone}/${id}/position?date=${date.format('YYYY-MM-DD')}&dateTo=${date.add(1, 'days').format('YYYY-MM-DD')}`
    date.add(-1, 'days').format('YYYY-MM-DD');
    axios.get(url)
      .then(async (res: any) => {
        if (res) {
          setData(res.data.data);
          setMakker([res.data.data[0].lat, res.data.data[0].long])
          setSelect(moment(res.data.data[0].created_at))
          setDetail(res.data.data[0]?.detail)

        }
      })
  }
  useEffect(() => {
    fetchMembers();
  }, []);

  const onPanelChange = (value: Moment, mode: CalendarMode) => {
    // fetchMembers(value);
    console.log(value.format('YYYY-MM-DD'), mode);
    let newValue = value;
    console.log(newValue)
    setValue(newValue);
    fetchMembers(newValue);
  };
  const onSelect = (value: Moment) => {
    let newValue = value;
    console.log(newValue)
    setValue(newValue);
    fetchMembers(newValue);
  };
  const Reset = styled.div``
  return (
    <div>
      <HeaderBar title="ข้อมูลตำแหน่ง" />
      <div style={{ padding: "10px 40px" }}>
        <p>{data?.members?.firstName} {data?.members?.lastName}</p>
        <div style={{ marginTop: "20px" }}>
          <div className="site-card-border-less-wrapper">
            <Row>
              <Col span={24} className="card-box">
                <Row>
                  <Col span={12}>
                    ที่อยู่ล่าสุด
                  </Col>
                  <Col span={12}><Icon icon="ic:round-alarm" /> {moment(select).format('Do MMMM YYYY, h:mm:ss a')}</Col>
                </Row>
                <Divider>รายละเอียด</Divider>
                <Row>
                  <Col span={12}>
                    {detail}
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <div>
                      {
                        makker.length > 0 &&
                        <Reset>
                          <GoogleMapReact
                            bootstrapURLKeys={{ key: "" }}
                            defaultCenter={{
                              lat: 10.99835602,
                              lng: 77.01502627
                            }}
                            defaultZoom={{ zoom: 11 }}
                          >
                            {/* <Markers
                              key={1}
                              text={'My Phone'}
                              lat={10.99835602}
                              lng={77.01502627}
                            /> */}
                          </GoogleMapReact>
                          <MapContainer center={makker} zoom={13} scrollWheelZoom={false}>
                            <TileLayer
                              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                            <Marker position={makker} icon={BoatIcon}>
                              <Popup>
                                ที่อยู่
                              </Popup>
                            </Marker>

                          </MapContainer>
                        </Reset>
                      }

                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </div>
        <div style={{ marginTop: "20px" }}>
          <Row gutter={16}>
            <Col span={12}>
              <Table columns={columns} dataSource={data} />
            </Col>
            <Col span={12}>
              <Calendar value={value} onSelect={onSelect} onPanelChange={onPanelChange} />
            </Col>
          </Row>
        </div>
      </div >
    </div >
  );
};
export default PositionPage;
