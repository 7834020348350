

import { Row, Col, Input } from 'antd';
import HeaderBar from "../../components/header/headerBar";
import { useState } from "react";
import numberToWords from "number-to-words";
const NumberToTextPage = () => {
  const [data, setData] = useState<number>(10000);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setData(parseInt(value));
  };
  const NumberFormat = (number: number) => {
    return new Intl.NumberFormat().format(number)
  }
  const BAHTTEXT: string | any = (num: number | string, suffix: string) => {
    if (typeof suffix === 'undefined') {
      suffix = 'บาทถ้วน';
    }
    num = num || 0;
    let StrNum = num.toString().replace(/[, ]/g, '');
    if (isNaN(parseFloat(StrNum)) || (Math.round(parseFloat(StrNum) * 100) / 100) === 0) {
      return 'ศูนย์บาทถ้วน';
    } else {
      var t = ['', 'สิบ', 'ร้อย', 'พัน', 'หมื่น', 'แสน', 'ล้าน'],
        n = ['', 'หนึ่ง', 'สอง', 'สาม', 'สี่', 'ห้า', 'หก', 'เจ็ด', 'แปด', 'เก้า'],
        len,
        digit,
        text = '',
        parts: string[] | any,
        i;

      if (StrNum.indexOf('.') > -1) {

        parts = StrNum.toString().split('.');

        num = parts[0];
        parts[1] = parseFloat('0.' + parts[1]);
        parts[1] = (Math.round(parts[1] * 100) / 100).toString(); // more accurate than toFixed(2)
        parts = parts[1].split('.');

        if (parts.length > 1 && parts[1].length === 1) {
          parts[1] = parts[1].toString() + '0';
        }

        num = parseInt(num.toString(), 10) + parseInt(parts[0], 10);

        text = num ? BAHTTEXT(num) : '';

        if (parseInt(parts[1], 10) > 0) {
          text = text.replace('ถ้วน', '') + BAHTTEXT(parts[1], 'สตางค์');
        }
        return text;
      } else {
        if (StrNum.length > 7) {
          var overflow = StrNum.substring(0, StrNum.length - 6);
          var remains = StrNum.slice(-6);
          return BAHTTEXT(overflow).replace('บาทถ้วน', 'ล้าน') + BAHTTEXT(remains).replace('ศูนย์', '');

        } else {

          len = StrNum.length;
          for (i = 0; i < len; i = i + 1) {
            digit = parseInt(StrNum.charAt(i), 10);
            if (digit > 0) {
              if (len > 2 && i === len - 1 && digit === 1 && suffix !== 'สตางค์') {
                text += 'เอ็ด' + t[len - 1 - i];
              } else {
                text += n[digit] + t[len - 1 - i];
              }
            }
          }
          text = text.replace('หนึ่งสิบ', 'สิบ');
          text = text.replace('สองสิบ', 'ยี่สิบ');
          text = text.replace('สิบหนึ่ง', 'สิบเอ็ด');

          return text + suffix;
        }
      }
    }
  }
  return (
    <>
      <HeaderBar title="Number To Text Demo" btnBack={true} />
      <div style={{ padding: "10px 40px" }}>
        <div className="create-page">
          <div className="title-header">
            <h3>Number To Text Demo</h3>
          </div>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Input placeholder="Basic usage" type="number" value={data} onChange={handleChange} />
              <h3>
                <p>{BAHTTEXT(NumberFormat(data))} ({numberToWords.toWordsOrdinal(data)} baht only)</p>
              </h3>
            </Col>

          </Row>
        </div>
      </div>
    </>
  );
};
export default NumberToTextPage;
