import React from "react";
import Icon from "@mdi/react";
import { mdiMagnify } from "@mdi/js";
import "../../assets/styles/components/textfield.scss";

interface Props {
  placeholder?: string;
  color?: string;
  className?: string;
  value?: string;
  onChanage?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const SearchField: React.FC<Props> = ({
  placeholder,
  onChanage,
  color = "#f4f4f4",
  className = "",
  value,
}) => {
  return (
    <div
      className={`search-field ${className}`}
      style={{ backgroundColor: color }}
    >
      <input
        type="text"
        placeholder={placeholder}
        onChange={onChanage}
        value={value}
      />
      <span>
        <Icon path={mdiMagnify} size="30" color="#5BAEA9" />
      </span>
    </div>
  );
};

export default SearchField;
