const getLocalUrl = (file) => {
  // console.log("get local url...");
  if (file instanceof File) {
    return URL.createObjectURL(file);
  }
  return file;
};

const replaceEditor = (text) => {
  if (!text) return "";
  return text.replace(/&lt;/g, "<");
};

const getFileHostUrl = (url) => {
  if (!url) return "";
  if (typeof url === "string") {
    if (url.startsWith("/") || url.startsWith("\\")) {
      url = `/static${url}`;
    }
  } else {
    url = getLocalUrl(url);
  }
  return url;
};

const getFileHostUrls = (urls) => {
  return urls.map((x) => {
    return getFileHostUrl(x);
  });
};

const isNumber = (event, isAllowDot = true) => {
  if (
    (!/\d/.test(event.key) && event.key !== ".") ||
    (!isAllowDot && event.key === ".")
  )
    return event.preventDefault();
};

const numberWithCommas = (x, digit = 0) => {
  if (!x || !Number(x)) return (0).toFixed(digit).toString();
  x = x.toFixed(digit);
  var parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
};

// Add a request interceptor
const setBearerToken = (axios, isClearToken, token) => {
  if (!token) token = localStorage.getItem("token");

  axios.interceptors.request.use(function (config) {
    config.headers.Authorization = `Bearer ${!isClearToken ? token : null}`;
    return config;
  });

  console.log("token setted...", token);

  const getToken = localStorage.getItem("token");
  console.log("getToken =>", getToken);
};

module.exports = {
  getLocalUrl,
  replaceEditor,
  getFileHostUrl,
  getFileHostUrls,
  isNumber,
  numberWithCommas,
  setBearerToken,
};
