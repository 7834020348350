import HeaderBar from "../../components/header/headerBar";
import { useHistory } from "react-router";
import { Modal, Table, Row, Col  } from "antd";
import type { ColumnsType } from 'antd/es/table';
import { useEffect, useState } from "react";
import environment from "../../config/environment";
import axios from "axios";
import { ButtonLink } from "../../components/button";
import { EyeOutlined, EditOutlined } from '@ant-design/icons';

const EmployeePage = () => {
  const history = useHistory();
  const [data, setData] = useState<any>([])
  const [open, setOpen] = useState(false);
  const [selectUser, setSelectUser] = useState<any>({})
  const columns: ColumnsType<any> = [
    {
      title: 'ลำดับ',
      dataIndex: '_id',
      key: '_id',
      align: "center" as "center",
      render: (_: any, user: any, key: number)  => <>{key+1}</>,
      width : 10,
    },
    {
      title: 'ชื่อ - นามสกุล',
      dataIndex: 'firstname',
      align: "center" as "center",
      key: 'firstname',
      render: (_: any, user: any) => <>{user?.firstname} {user?.lastname}</>,
    },
    {
      title: 'เพศ',
      key: 'gender',
      dataIndex: 'gender',
      align: "center" as "center",
      render: (_, { gender }) => (
        <>
          {
            gender === 'male' ?
              'ชาย'
              :
              'หญิง'
          }
        </>
      ),
    },
    {
      title: 'อายุ',
      key: 'birthday',
      dataIndex: 'birthday',
      align: "center" as "center",
      render: (_, { birthday }) => {
        var dob = new Date(birthday);
        var month_diff = Date.now() - dob.getTime();
        var age_dt = new Date(month_diff);
        var year = age_dt.getUTCFullYear();
        var age = Math.abs(year - 1970);
        return (
          <>
            {
              age
            }
          </>
        )
      }
    },
    {
      title: '',
      key: 'tags',
      dataIndex: 'tags',
      align: "center" as "center",
      render: (_: any, user: any) => (
        <Row>
          <Col span={12}><EyeOutlined onClick={() => showModal(user)} /></Col>
          <Col span={12}><EditOutlined onClick={() => history.push("/employee/edit/"+user._id)} /></Col>
        </Row>
      ),
    }
  ];
  const showModal = (data) => {
    setSelectUser(data);
    setOpen(true);
  };
  const handleCancel = () => {
    setOpen(false);
  };
  const handleOk = () => {
    setOpen(false);
  };

  useEffect(() => {
    getData();
  }, []);
  const getData = () => {
    axios.get(environment.employee).then(res => {
      let { data } = res?.data;
      setData([...data])
    });
  }
  return (
    <div>
      <HeaderBar title="รายชื่อพนักงาน" />
      <div style={{ padding: "10px 40px" }}>
      <Modal title={`ข้อมูลพนักงาน | ${selectUser?.firstname} ${selectUser?.lastname}`} open={open} onOk={handleOk} onCancel={handleCancel}>
        <p>ชื่อ - นามสุกล : {selectUser?.firstname} {selectUser?.lastname}</p>
        <p>เพศ : {selectUser?.gender}</p>
        <p>เกิดวันที่ : {selectUser?.birthday}</p>
        <p>เริ่มงานวันที่ : {selectUser?.jobStartDate}</p>
        <p>ประจำสาขา : {selectUser?.branch?.nameTH}</p>
        <p>เบอร์โทรติดต่อ : {selectUser?.numberPhone}</p>
        <p>ที่อยู่ : {selectUser?.address}</p>
      </Modal>
        <div style={{ marginTop: "20px", display: "grid", justifyContent: "end" }}>
          <ButtonLink onClick={() => history.push("/employee/create")}>
            เพิ่มพนักงาน
          </ButtonLink>
        </div>

        <div style={{ marginTop: "20px" }}>
          <Table columns={columns} dataSource={data} />
        </div>
      </div>
    </div>
  );
};
export default EmployeePage;
