import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Layout from "../components/layout/layout";
import OtherLayout from "../components/layout/otherLayout";
import Index from "../pages/index";

import Login from "../pages/login/loginPage";


//? Member
import MemberPage from "../pages/member"
import MemberDetailPage from "../pages/member/detail";
import PositionPage from "../pages/member/position"
import ReceiptPage from "../pages/member/receipt";

import PrintPage from "../pages/print";
import NumberToTextPage from '../pages/NumberToText'
import BranchPage from "../pages/branch"
import CompanyPage from "../pages/company"
import EmployeePage from "../pages/employee"
import EmployeeCreatePage from "../pages/employee/create"
import NotifyPage from "../pages/notify"
import LogsPage from "../pages/logs"
import WebviewPage from "../pages/Webview";
const Routes = () => {
  return (
    <Router>
      <Switch>
        <Route path="/" exact>
          <Login />
        </Route>
        <Route path="/dashboard">
          <Layout noHeader={true}>
            <Index />
          </Layout>
        </Route>
        <Route path="/member" exact>
          <Layout>
            <MemberPage />
          </Layout>
        </Route>
        <Route path="/member/:id/receipt" exact>
          <Layout>
            <ReceiptPage />
          </Layout>
        </Route>
        <Route path="/member/:id/position" exact>
          <Layout>
            <PositionPage />
          </Layout>
        </Route>
        <Route path="/member/:id/detail" exact>
          <Layout>
            <MemberDetailPage mode="edit"/>
          </Layout>
        </Route>
        <Route path="/employee" exact>
          <Layout>
            <EmployeePage />
          </Layout>
        </Route>
        <Route path="/employee/create" exact>
          <Layout>
            <EmployeeCreatePage mode="add" />
          </Layout>
        </Route>
        <Route path="/employee/edit/:id" exact>
          <Layout>
            <EmployeeCreatePage mode="edit"/>
          </Layout>
        </Route>
        <Route path="/company" exact>
          <Layout>
            <CompanyPage />
          </Layout>
        </Route>
        <Route path="/branch" exact>
          <Layout>
            <BranchPage />
          </Layout>
        </Route>
        <Route path="/notify" exact>
          <Layout>
            <NotifyPage />
          </Layout>
        </Route>
        <Route path="/logs" exact>
          <Layout>
            <LogsPage />
          </Layout>
        </Route>
        <Route path="/print" exact>
          <Layout>
            <PrintPage />
          </Layout>
        </Route>
        <Route path="/NumberToText" exact>
          <Layout>
            <NumberToTextPage />
          </Layout>
        </Route>
        {/* ------------------------------------------------------ setting --------------------------------------------------- */}
        <Route path="/other" exact>
          <OtherLayout noHeader={true}>
            <Index />
          </OtherLayout>
        </Route>
        <Route path="/setting/personnel" exact>
          <OtherLayout title="PERSONNEL">
            <Index />
          </OtherLayout>
        </Route>
        <Route path="/webview">
          <WebviewPage/>
        </Route>
      </Switch>
    </Router>
  );
};
export default Routes;
