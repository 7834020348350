import { createAction } from "@reduxjs/toolkit";
interface User {
    Code : string;
    DepartmentCode : string;
    DepartmentId: number;
    DepartmentName : string;
    FullName  : string;
    FullNameEn  : string;
}


export const setUser = createAction("SET_USER", function prepare(
    user: User
) {
    return {
        payload: user
    };
});
