import HeaderBar from "../../components/header/headerBar";
import { Table  } from "antd";
import type { ColumnsType } from 'antd/es/table';
import { useEffect, useState } from "react";
import environment from "../../config/environment";
import axios from "axios";

const LogsPage = () => {
  const [data, setData] = useState<any>([])
  const columns: ColumnsType<any> = [
    {
      title: 'ลำดับ',
      dataIndex: '_id',
      key: '_id',
      align: "center" as "center",
      render: (_: any, user: any, key: number)  => <>{key+1}</>,
      width : 10,
    },
    {
      title: 'ข้อความ',
      dataIndex: 'message',
      align: "center" as "center",
      key: 'message',
      render: text => <>{text}</>,
    },
    {
      title: 'ดำเนินการโดย',
      key: 'by',
      dataIndex: 'by',
      align: "center" as "center",
    }
  ];


  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    axios.get(environment.logs).then(res => {
      let { data } = res?.data;
      setData([...data])
    });
  }
  return (
    <div>
      <HeaderBar title="Logs System" />
      <div style={{ padding: "10px 40px" }}>
        <div style={{ marginTop: "20px" }}>
          <Table columns={columns} dataSource={data} />
        </div>
      </div>
    </div>
  );
};
export default LogsPage;
