import React, { useState, useEffect, useRef } from "react";
import LOGO from "../../assets/images/logo.jpg";
import { useLocation } from "react-router-dom";
import { Carousel, Row, Col, Progress, Result } from "antd";
import { Container, ContainerFooter, ContainerRow, ContainerBox, BoxEnterCode, TextTile, TextTileH1, BTNLine, BTNFB } from './styled'
import moment from "moment";
import axios from "axios";
moment.locale('th')
const WebviewPage = (props: any) => {
  const ref = useRef<any>(null);
  const [pageSlide, setPageSlide] = useState(0);
  const [data, setData] = useState<any>({});
  const locationState = useLocation().state as { auth: string };
  const auth = locationState?.auth;
  const onChange = (currentSlide: number) => {
    setPageSlide(currentSlide);
    console.log(currentSlide);
  };
  const goTo = (slide) => {
    ref.current.goTo(slide, false);
  };
  const onChangePage = (currentSlide: number) => {
    setPageSlide(currentSlide);
    goTo(currentSlide);
  }
  useEffect(() => {
    console.log("start!")
    axios.post(`/phone/checkData`, { auth },
      {
        headers: {
          'Content-Type': 'application/json',
        }
      }
    ).then((res: any) => {
      let { data } = res.data;
      setData(data)
    }).catch((e: any) => {
    })
  }, [auth]);
  return (
    <>
      <Row>
        <Col>
          {
            !data?.action &&
            <Result
              status="error"
              title="อยู่ระหว่างการทำสัญญาเครื่อง"
              subTitle="กำลังดำเนินจัดทำสัญญาเช่าซื้อ"
            >
            </Result>
          }
          <Carousel ref={ref} afterChange={onChange} dots={false} style={{ marginBottom: "8rem" }}>
            <div>
              <Container>
                <div>
                  <div className="logo" style={{
                    background: `url(${LOGO})`,
                    backgroundSize: '100%',
                    backgroundRepeat: 'round'
                  }}> </div>
                  <div className="content">
                    <div>บริษัท ลี่โฟน 2019 จำกัด</div>
                    <div>LEE PHONE 2019 CO., LTD.</div>
                  </div>
                </div>
              </Container>
              <ContainerRow>
                <BoxEnterCode>
                  <div className="code">Enter Code</div>
                  <input type="text" />
                  <button>VALIDATE CODE</button>
                </BoxEnterCode>
              </ContainerRow>

              <ContainerRow>
                <ContainerBox>
                  <TextTile>Notification</TextTile>
                  <hr />
                  <div>
                    {/* <BoxAlert>
                      ssss
                      <div className="time">20.22</div>
                    </BoxAlert>
                    <BoxAlert>
                      ssss
                      <div className="time">20.22</div>
                    </BoxAlert>
                    <BoxAlert>
                      ssss
                      <div className="time">20.22</div>
                    </BoxAlert> */}
                  </div>
                </ContainerBox>
              </ContainerRow>
            </div>
            <div>
              <TextTileH1>
                <div>ข้อมูลสินเชื่อ</div>
                <div>Credit Information</div>
              </TextTileH1>
              <ContainerRow>
                <ContainerBox>
                  <TextTile style={{ textAlign: 'center' }}>{data.moblieModel}</TextTile>
                  <Row style={{
                    alignItems: "center",
                    textAlign: "center"
                  }}>
                    <Col span={6}>
                      <div>ชำระแล้ว</div>
                      <div>{data?.numberPaid}</div>
                      <div>งวด</div>
                    </Col>
                    <Col span={12}>
                      <Progress
                        showInfo={false}
                        strokeColor={{
                          from: '#108ee9',
                          to: '#87d068',
                        }}
                        percent={(data?.installments / data?.numberPaid) * 100}
                        status="active"
                      />
                    </Col>
                    <Col span={6}>
                      <div>คงเหลือ</div>
                      <div>{data?.installments - data?.numberPaid}</div>
                      <div>งวด</div>
                    </Col>
                  </Row>
                </ContainerBox>
              </ContainerRow>
              <ContainerRow>
                <ContainerBox>
                  <TextTile>เลขที่สัญญาเช่าซื้อ</TextTile>
                  <p>{data._id}</p>
                  <hr />
                  <TextTile>IMEI NO </TextTile>
                  <p>{data.imei}</p>
                  <hr />
                  <TextTile>รุ่น </TextTile>
                  <p>{data.moblieModel}</p>
                  <hr />
                  <TextTile>ค่างวด </TextTile>
                  <p>{data.installment}</p>
                  <hr />
                  <TextTile>กำหนดชำระ </TextTile>
                  <p>{moment(data.paymentDeadline).format('Do MMMM YYYY')}</p>
                  <hr />
                </ContainerBox>
              </ContainerRow>
            </div>
            <div>
              <TextTileH1>
                <div>ติดต่อ</div>
                <div>Contact</div>
              </TextTileH1>
              <ContainerRow>
                <ContainerBox>
                  <TextTile>บริษัท ลี่โฟน 2019 จำกัด</TextTile>
                  <TextTile>LEE PHONE 2019 CO., LTD.</TextTile>
                  <hr />
                  <TextTile>ที่อยู่ / Address</TextTile>
                  <p>357/1 ถนนป่าตัน ตำบลป่าตัน อำเภอเมืองเชียงใหม่ จังหวัดเชียงใหม่ 50300</p>
                  <hr />
                  <TextTile>ที่อยู่ / Address</TextTile>
                  <div>099-370-2444</div>
                  <div>098-777-3533</div>
                </ContainerBox>
              </ContainerRow>
              <ContainerRow style={{
                display: "flex",
                justifyContent: "center"
              }}>
                <BTNLine>@Line</BTNLine>
                <BTNFB>Facebook</BTNFB>
              </ContainerRow>
            </div>
          </Carousel>
        </Col>
      </Row>
      <ContainerFooter>
        <div>
          <div className={pageSlide === 0 ? `activity` : ''} onClick={() => onChangePage(0)}>หน้าหลัก
            Home</div>
          <div className={pageSlide === 1 ? `activity` : ''} onClick={() => onChangePage(1)}>ข้อมูลสินเชื่อ
            Credit Information</div>
          <div className={pageSlide === 2 ? `activity` : ''} onClick={() => onChangePage(2)}>
            ติดต่อ
            Contact
          </div>
        </div>
      </ContainerFooter>
    </>

  );
};
export default WebviewPage;
