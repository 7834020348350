import React, { useState, useEffect } from "react";
import styled from 'styled-components';
import queryString from 'query-string';
import { useHistory, useLocation } from "react-router-dom";
import { Row, Col, message } from "antd";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import LOGO from "../../assets/images/logo.jpg";
import { setBearerToken } from '../../helpers/functions'
import Input from "../../components/textfield/input";
const FormLogin = styled.div`
width: 465px;
display: grid;
align-items: center;
justify-items: center;
margin: 10vh auto;
top: 10vh;
.input{
  margin-bottom: 1rem;
  input {
    background: #E0E0E0;
    border-radius: 0.5rem;
    border: 0;
  }
}
`;
const InputForm = styled.div`
width: 100%;
padding: 1.5rem;
background: #ffff;
border-radius: 0.6em;
`;
const LoginButtom = styled.button`
background: #000;
color: #fff;
width: 100%;
border-radius: 50rem;
padding: 0.5rem 0;
`;
const LoginPage = (props: any) => {
  const [loading, setLoading] = useState(false);
  const { search } = useLocation();
  const [username, setUsername] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const dispatch = useDispatch();
  const { loader } = useSelector((state: any) => state);
  const history = useHistory();
  useEffect(() => {
    const parsed = queryString.parse(search);
    console.log(search)
    if (parsed.mode === "webview") {
      history.push({
        pathname : '/webview',
        state: { auth : parsed.auth}
      });
    } else {
      setLoading(true);
      let token: string | null | any = localStorage.getItem("token");
      if (token?.length > 0 && !loader.isFetchedUser) {
        dispatch({
          type: "isFetchedUser",
          payload: true,
        });
        history.push('/dashboard');
      }
    }
  }, [loader, history, search, dispatch]);

  const onLogin = () => {
    axios.post(`/login`, { username, password },
      {
        headers: {
          'Content-Type': 'application/json',
        }
      }
    ).then((res: any) => {
      let { token } = res.data;
      dispatch({
        type: "isFetchedUser",
        payload: true,
      });
      localStorage.setItem("token", token);
      setBearerToken(axios, false, token);
      history.push('/dashboard');
    }).catch((e: any) => {
      let { data } = e?.response;
      for (let i = 0; i < data.error.errors.length; i++) {
        message.error(data.error.errors[i].msg);
      }
    })
  }
  return (
    <>
      {
        loading &&
        <Row gutter={[24, 16]}>
          <Col span={24}>
            <FormLogin>
              <img className="logo" src={LOGO} alt="Lee Phone" />
              <InputForm>
                <Input
                  title="Username"
                  value={username}
                  onChanage={(e) => setUsername(e.target.value)}
                />
                <Input
                  title="Password"
                  type="password"
                  value={password}
                  onChanage={(e) => setPassword(e.target.value)}
                />
                <LoginButtom onClick={() => onLogin()}>Login</LoginButtom>
              </InputForm>
            </FormLogin>

          </Col>
        </Row>
      }

    </>

  );
};
export default LoginPage;
