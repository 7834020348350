import HeaderBar from "../../components/header/headerBar";
import { SearchField } from "../../components/textfield";
import { Modal, Table, Row, Col, Button, Form, Input } from "antd";
import type { ColumnsType } from 'antd/es/table';
import { useEffect, useState } from "react";
import environment from "../../config/environment";
import axios from "axios";
import { ButtonLink } from "../../components/button";


const BranchPage = () => {
  const [data, setData] = useState<any>([])
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [selectRow, setSelectRow] = useState<any>({})
  const columns: ColumnsType<any> = [
    {
      title: 'id',
      dataIndex: '_id',
      key: '_id',
      align: "center" as "center",
      render: text => <>{text}</>,
    },
    {
      title: 'สาขา',
      dataIndex: 'nameTH',
      align: "center" as "center",
      key: 'nameTH',
      width: "60%",
    },
    {
      title: '',
      key: 'tags',
      dataIndex: 'tags',
      align: "center" as "center",
      render: (_:any, row : any) => (
        <Row>
          <Col span={11} offset={1}><ButtonLink onClick={() => showModalEdit(row)}>แก้ไขข้อมูล</ButtonLink></Col>
          <Col span={11} offset={1}><ButtonLink>ลบ</ButtonLink></Col>
        </Row>
      ),
    }
  ];
  const showModalEdit = (data) => {
    setEdit(true);
    setSelectRow(data)
  };
  const showModal = () => {
    setOpen(true);
  };
  const handleCancel = () => {
    setOpen(false);
  };
  const handleCancelEdit = () => {
    setEdit(false);
    setSelectRow({});
  };
  const onFinish = (values: any) => {
    console.log('Success:', values);
    axios.patch(environment.branch, values).then(res => {
      getData()
      handleCancel()
    });
  };
  const onFinishEdit = (values: any) => {
    axios.patch(environment.branch, selectRow).then(res => {
      getData()
      handleCancelEdit()
    });
  };
  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);

  };

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    axios.get(environment.branch).then(res => {
      let { data } = res?.data;
      setData([...data])
    });
  }
  return (
    <div>
      <HeaderBar title="ข้อมูลสาขา" />
      <div style={{ padding: "10px 40px" }}>
        <SearchField
          placeholder="ค้นหาสาขา"
          color="#fff"
        />
        <Modal
          open={open}
          title="เพิ่มสาขา"
          footer={null}
          onCancel={handleCancel}
        >
          <Form
            name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              label="ชื่อสาขา"
              name="nameTH"
              rules={[{ required: true, message: 'Please input your name!' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
              <Button onClick={handleCancel} style={{ marginLeft: "20px", marginRight: "20px" }}>
                Cancel
              </Button>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Modal>
        <Modal
          open={edit}
          title="แก้สาขา"
          footer={null}
          onCancel={handleCancelEdit}
        >
          <Form
            name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            initialValues={{ remember: true }}
            onFinish={onFinishEdit}
            autoComplete="off"
          >
            <Form.Item
              label="ชื่อสาขา"
              name="nameTH"
              rules={[{ required: true, message: 'Please input your name!' }]}
            >
              <Input defaultValue={selectRow?.nameTH} onChange={(e) => setSelectRow({...selectRow, nameTH : e.target.value})} />
            </Form.Item>
            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
              <Button onClick={handleCancelEdit} style={{ marginLeft: "20px", marginRight: "20px" }}>
                Cancel
              </Button>
              <Button type="primary" htmlType="submit">
                Save
              </Button>
            </Form.Item>
          </Form>
        </Modal>
        <div style={{ marginTop: "20px", display: "grid", justifyContent: "end" }}>
          <ButtonLink onClick={showModal}>
            เพิ่มสาขา
          </ButtonLink>
        </div>
        <div style={{ marginTop: "20px" }}>
          <Table columns={columns} dataSource={data} />
        </div>
      </div>
    </div>
  );
};
export default BranchPage;
