import { combineReducers } from "@reduxjs/toolkit";
import { authReducer } from './store/auth';
import { userReducer } from './store/user';
import { loaderReducer } from './store/loader';
const rootReducer = combineReducers({
    user: userReducer,
    loader : loaderReducer,
    auth: authReducer
});
export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;