import { createAction } from "@reduxjs/toolkit";
import axios from "axios";
import environment from "../../config/environment";
interface Loader {
    isLoading: boolean;
}

interface Alert {
    isVisible: boolean;
    message: string;
}
const setSessionKey = (data: object, token: string) => {

};


function loginKey(key: string, history: any) {
    const data = {
        "Username": "",
        "Password": "",
        "IPAddress": "127.0.0.1",
        "Encrypt": key
    }
    fetch(environment.jumpapp, {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    }).then((response) => response.json())
        .then((responseJson) => {
            console.log(responseJson)
            if (responseJson[0][0].IsSuccess) {
                setSessionKey(responseJson[2][0], responseJson[1][0].Token)
                history.push("/")
                return responseJson[2][0];
            }
            return responseJson

        })
        .catch((error) => {
            console.error(error);
        });
    axios.post(environment.jumpapp, data).then(res => {
        if (res.data[0][0].IsSuccess) {
            console.log(res.data)
            setSessionKey(res.data[2][0], res.data[1][0].Token)
            history.push("/")
            return res.data[2][0];
        }
        return res
    });
}
export const loginUserKey = createAction("LOGIN_USER_KEY", function prepare(
    key: string,
    history: object
) {
    return {
        payload: loginKey(key, history)
    }


});



export const setLoader = createAction("LOADER", function prepare(
    loader: Loader
) {
    return {
        payload: loader
    };
});

export const setAlert = createAction("ALERT", function prepare(alert: Alert) {
    return {
        payload: alert
    };
});
