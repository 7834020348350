import LOGO from "../assets/images/logo.jpg";

import "../assets/styles/admin/home.scss";
import { Row, Col } from 'antd';
const Index = () => {
  return (
    <div className="home-page">
      <Row gutter={16}>
        {/* <Col span={24} style={{
            display : 'flex',
            justifyContent: 'center'
          }} className="mb-2"><img src={LOGO} /></Col> */}
          <Col span={24}  className="mt-2 ml-2">
            <Row gutter={16}>
              <Col className="text-center" style={{
                borderRadius : '0.4rem',
                backgroundColor : "#FFFF"
                }} xs={{ span: 4, offset: 1 }}>
                <h4>Dowload</h4>
                <img src={LOGO} width="100%" alt="LOGO" /><br/>
                APK Android 7.1 (API 25) <br/>
                <img src={"https://qrcode.tec-it.com/API/QRCode?data=https://storage.mtf.in.th/app-debug.apk"} width="100%" alt="LOGO" /><br/>
              </Col>
              <Col className="text-center" style={{
                borderRadius : '0.4rem',
                backgroundColor : "#FFFF"
                }} xs={{ span: 4, offset: 1 }}>
                <h4>Dowload</h4>
                <img src={LOGO} width="100%" alt="LOGO" /><br/>
                APK Android 9+ (API 25)<br/>
                <img src={"https://qrcode.tec-it.com/API/QRCode?data=https://storage.mtf.in.th/app-debug.apk"} width="100%" alt="LOGO" /><br/>
                -
              </Col>
              <Col className="text-center" style={{
                borderRadius : '0.4rem',
                backgroundColor : "#FFFF"
                }} xs={{ span: 4, offset: 1 }}>
                <h4>Dowload</h4>
                <img src={LOGO} width="100%" alt="LOGO" /><br/>
                APK Huawei)<br/>
                {/* <img src={"https://qrcode.tec-it.com/API/QRCode?data=https://storage.mtf.in.th/app-debug.apk"} width="100%" /><br/> */}
              </Col>
            </Row>
          </Col>
        </Row>
    </div>
  );
};
export default Index;
