import React, { useState } from "react";
import SettingSideBar from "../sideBar/settingSideBar";
import MainBody from "./mainBody";

interface Props {
  children: object;
  noHeader?: boolean;
  firstPage?: boolean;
  title?: string;
  btnBack?: boolean;
}

const SettingLayout: React.FC<Props> = ({
  children,
  noHeader = false,
  firstPage = false,
  btnBack = false,
  title = "",
}) => {
  const [toggled, setToggled] = useState(false);

  const handleToggleSidebar = (value: boolean) => {
    setToggled(value);
  };

  return (
    <div className={`app ${toggled ? "toggled" : ""}`}>
      <SettingSideBar
        toggled={toggled}
        handleToggleSidebar={handleToggleSidebar}
      />
      <MainBody
        title={title}
        noHeader={noHeader}
        firstPage={firstPage}
        btnBack={btnBack}
        handleToggleSidebar={handleToggleSidebar}
      >
        {children}
      </MainBody>
    </div>
  );
};

export default SettingLayout;
