import HeaderBar from "../../components/header/headerBar";
import { SearchField } from "../../components/textfield";
import { Modal, Table, Row, Col, Button, Form, Input } from "antd";
import type { ColumnsType } from 'antd/es/table';
import { useEffect, useState } from "react";
import environment from "../../config/environment";
import axios from "axios";
import { ButtonLink } from "../../components/button";


const CompanyPage = () => {
  const { TextArea } = Input;
  const [formEdit] = Form.useForm();
  const [data, setData] = useState<any>([])
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const columns: ColumnsType<any> = [
    {
      title: 'id',
      dataIndex: '_id',
      key: '_id',
      align: "center" as "center",
      render: text => <>{text}</>,
    },
    {
      title: 'สาขา',
      dataIndex: 'nameTH',
      align: "center" as "center",
      key: 'nameTH',
      width: "60%",
    },
    {
      title: '',
      key: '_id',
      dataIndex: '_id',
      align: "center" as "center",
      render: (_, data) => (
        <Row>
          <Col span={11} offset={1}><ButtonLink onClick={() => showEditModal(data)}>แก้ไขข้อมูล</ButtonLink></Col>
          <Col span={11} offset={1}><ButtonLink>ลบ</ButtonLink></Col>
        </Row>
      ),
    }
  ];
  const showEditModal = (data) => {
    formEdit.setFieldValue("_id", data?._id)
    formEdit.setFieldValue("nameTH", data?.nameTH)
    formEdit.setFieldValue("address", data?.address)
    setOpenEdit(true);
  };
  const showModal = () => {
    setOpen(true);
  };
  const handleCancel = () => {
    setOpen(false);
    setOpenEdit(false)
  };

  const onFinish = (values: any) => {
    console.log('Success:', values);
    axios.post(environment.company, values).then(res => {
      getData()
      handleCancel()
    });
  };
  const onFinishEdit = (values: any) => {
    console.log('Success:', values);
    axios.patch(environment.company, values).then(res => {
      getData()
      handleCancel()
    });
  };
  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);

  };

  useEffect(() => {
    getData();
  }, []);
  const getData = () => {
    axios.get(environment.company).then(res => {
      let { data } = res?.data;
      setData([...data])
    });
  }
  return (
    <div>
      <HeaderBar title="ข้อมูลบริษัท" />
      <div style={{ padding: "10px 40px" }}>
        <SearchField
          placeholder="ค้นหาสาขา"
          color="#fff"
        />
        <Modal
          open={open}
          title="เพิ่มบริษัท"
          footer={null}
          onCancel={handleCancel}
        >
          <Form
            name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              label="ชื่อบริษัท"
              name="nameTH"
              rules={[{ required: true, message: 'กรุณาใส่ชื่อบริษัท !' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="เบอร์โทรบริษัท"
              name="numberPhone"
              rules={[{ required: true, message: 'กรุณาใส่เบอร์โทรบริษัท !' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="ที่อยู่บริษัท"
              name="address"
            >
              <TextArea rows={4} />
            </Form.Item>
            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
              <Button onClick={handleCancel} style={{ marginLeft: "20px", marginRight: "20px" }}>
                ยกเลิก
              </Button>
              <Button type="primary" htmlType="submit">
                บันทึก
              </Button>
            </Form.Item>
          </Form>
        </Modal>
        <Modal
          open={openEdit}
          title="แก้ไขข้อมูลบริษัท"
          footer={null}
          onCancel={handleCancel}
        >
          <Form
            form={formEdit}
            name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            initialValues={{ remember: true }}
            onFinish={onFinishEdit}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              label=""
              name="_id"
              hidden
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="ชื่อบริษัท"
              name="nameTH"
              rules={[{ required: true, message: 'กรุณาใส่ชื่อบริษัท !' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="เบอร์โทรบริษัท"
              name="numberPhone"
              rules={[{ required: true, message: 'กรุณาใส่เบอร์โทรบริษัท !' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="ที่อยู่บริษัท"
              name="address"
            >
              <TextArea rows={4} />
            </Form.Item>
            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
              <Button onClick={handleCancel} style={{ marginLeft: "20px", marginRight: "20px" }}>
                ยกเลิก
              </Button>
              <Button type="primary" htmlType="submit">
                บันทึก
              </Button>
            </Form.Item>
          </Form>
        </Modal>
        <div style={{ marginTop: "20px", display: "grid", justifyContent: "end" }}>
          <ButtonLink onClick={showModal}>
            เพิ่มบริษัท
          </ButtonLink>
        </div>
        <div style={{ marginTop: "20px" }}>
          <Table columns={columns} dataSource={data} />
        </div>
      </div>
    </div>
  );
};
export default CompanyPage;
