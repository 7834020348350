import React from "react";
import { Icon } from "@iconify/react";
import { useHistory } from "react-router-dom";
import "../../assets/styles/components/header.scss";
interface Props {
  noHeader?: boolean;
  firstPage?: boolean;
  btnBack?: boolean;
  onClick?: () => void;
  title?: string;
  children?: object;
}

const HeaderBar: React.FC<Props> = ({
  noHeader = false,
  btnBack = false,
  title = "",
  children,
  onClick
}) => {
  let history = useHistory();

  return (
    <div className="header-bar">
      <div style={{ display: "flex", alignItems: "end", marginLeft: "20px" }}>
        {btnBack ? (
          <div style={{ cursor: "pointer" }} onClick={history.goBack}>
            <Icon
              icon="akar-icons:chevron-left"
              width="1.3rem"
              height="1.3rem"
              style={{ marginRight: "20px", marginBottom: " 4px" }}
            />
          </div>
        ) : (
          <div>
            {
              onClick ?
                <div style={{ cursor: "pointer" }} onClick={onClick}>
                  <Icon
                    icon="akar-icons:chevron-left"
                    width="1.3rem"
                    height="1.3rem"
                    style={{ marginRight: "20px", marginBottom: " 4px" }}
                  />
                </div>
                :
                ""
            }
          </div>
        )}

        {title}
      </div>
    </div >
  );
};
export default HeaderBar;
