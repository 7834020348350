import { Button, Row, Col, Card, Form, Input, Select, notification } from 'antd';
import React from 'react';
import HeaderBar from "../../components/header/headerBar";
import { useEffect, useState } from "react";
import environment from "../../config/environment";
import { axios } from "../../axios";
const PrintPage: React.FC = () => {
  const [selectCompany, setSelectCompany] = useState<string>("")
  const [company, setCompany] = useState<any>([]);
  const [condition, setCondition] = useState<string>("")
  const [paymentDefault, setPaymentDefault] = useState<string>("")


  useEffect(() => {
    getData();
  }, [])
  useEffect(() => {
    getSetting();
  }, [selectCompany])
  const getData = () => {
    axios.get(environment.company).then(res => {
      let { data } = res?.data;
      setCompany([...data])
      setSelectCompany(data[0]._id)
    });
  }
  const getSetting = () => {
    axios.get(`${environment.setting}/${selectCompany}`).then(res => {
      let { data } = res?.data;
      setCondition(data?.condition)
      setPaymentDefault(data?.payment_default)
    });
  }
  const updateSetting = () => {
    axios.patch(`${environment.setting}/${selectCompany}`, {
      condition,
      payment_default: paymentDefault
    }).then(res => {
      let { data } = res?.data;
      getSetting();
    }).catch(e => {
      notification.open({
        message: 'Notification',
        description: e,
        duration: 0,
      });
    });
  }
  return <>
    <HeaderBar title="Notify Moblie" btnBack={false} />
    <div style={{ padding: "10px 40px" }}>
      <div className="create-page">
        <div className="title-header">
          <h3>ตั้งค่าข้อความ Notify Moblie</h3>
        </div>
        <Row gutter={[16, 16]} className='mb-4'>
          <Col span={11} offset={1}>
            <Card title="บริษัท" bordered={true}>
              <Form name="nest-messages" >
                {
                  selectCompany !== "" &&
                  <Select className='w-100'
                    defaultValue={selectCompany}
                    onChange={(e) => setSelectCompany(e)}
                    style={{ width: 120 }}
                    options={
                      company.map((i) => {
                        return {
                          value: i?._id,
                          label: i?.nameTH
                        }
                      })
                    }
                  />
                }

              </Form>
            </Card>
          </Col>
          <Col span={11} offset={1}></Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={11} offset={1}>
            <Card title="ตั้งค่าข้อความทั่วไป" bordered={true}>
              {
                condition &&
                <Form name="nest-messages" >
                  <Form.Item name={['user', 'introduction']} label="ข้อความ">
                    <Input.TextArea disabled={selectCompany === "" ? true : false} defaultValue={condition} value={condition} />
                  </Form.Item>
                </Form>
              }
            </Card>
          </Col>
          <Col span={11} offset={1}>
            {
              paymentDefault &&
              <Card title="ตั้งค่าข้อความผิดนัด" bordered={true}>
                <Form name="nest-messages" >
                  <Form.Item name={['user', 'introduction']} label="ข้อความ">
                    <Input.TextArea disabled={selectCompany === "" ? true : false} defaultValue={paymentDefault} value={paymentDefault} />
                  </Form.Item>
                </Form>
              </Card>
            }

          </Col>
          <Col span={23} offset={1}>
            <Button type="primary" onClick={() => updateSetting()} block>
              บันทึก
            </Button>
          </Col>
        </Row>
      </div>
    </div >
  </>;
};

export default PrintPage;