import HeaderBar from "../../components/header/headerBar";
import { Card, Row, Col, Button, Form, Input, Select, DatePicker, Divider, notification } from "antd";
import { useEffect, useState } from "react";
import environment from "../../config/environment";
import axios from "axios";
import { useHistory, useParams } from "react-router";
import moment from 'moment';
const { Option } = Select;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

const config = {
  rules: [{ type: 'object' as const, required: true, message: 'Please select time!' }],
};
interface Prop {
  mode: String;
  children?: React.ReactNode;
}

const EmployeeCreatePage: React.FC<Prop> = ({ mode }) => {
  const { TextArea } = Input;
  const { id } = useParams<{ id: string; }>();
  const history = useHistory();
  const [form] = Form.useForm();
  const [branch, setBranch] = useState<any>([]);
  const onGenderChange = (value: string) => {
    switch (value) {
      case 'male':
        form.setFieldsValue({ note: 'Hi, man!' });
        break;
      case 'female':
        form.setFieldsValue({ note: 'Hi, lady!' });
        break;
      case 'other':
        form.setFieldsValue({ note: 'Hi there!' });
        break;
      default:
    }
  };
  const onFinish = (values: any) => {
    console.log(values)
    axios.post(environment.employee, values).then(res => {
      const args = {
        message: 'Notification',
        description: mode === 'add' ? 'เพิ่มพนักงานสำเร็จแล้ว' : 'แก้ไขข้อมูลพนักงานสำเร็จแล้ว',
        duration: 0,
      };
      notification.open(args);
      history.push("/employee")
    });
  };

  const onReset = () => {
    form.resetFields();
  };

  useEffect(() => {
    const getEmployee = () => {
      axios.get(`${environment.employee}/${id}`).then(res => {
        let { data } = res?.data;
        form.setFieldsValue({
          _id: id,
          titleName: data?.titleName,
          firstname: data?.firstname,
          lastname: data?.lastname,
          gender: data?.gender,
          branch: data?.branch,
          birthday: moment(data?.birthday),
          jobStartDate: moment(data?.jobStartDate),
          jobEndDate: moment(data?.jobEndDate),
          numberPhone: data?.numberPhone,
          address: data?.address,
        });
      });
    }
    const getBranch = () => {
      axios.get(environment.branch).then(res => {
        let { data } = res?.data;
        setBranch([...data])
      });
    }
    getBranch();
    if (id !== null && id !== "") {
      getEmployee()
    }
  }, [form, id])

  return (
    <div>
      <HeaderBar title={mode === 'add' ? 'เพิ่มพนักงาน' : 'แก้ไขข้อมูลพนักงาน'} btnBack={true} />
      <div style={{ padding: "10px 40px" }}>
        <Row justify="center">
          <Col span={16}>
            <Card title="พนักงาน" bordered={false}>
              <Form
                {...layout}
                form={form}
                name="control-hooks"
                onFinish={onFinish}
              >
                <Row gutter={24}>
                  <Col span={12}>
                    <Form.Item name="titleName" label="คำนาม" rules={[{ required: true }]}>
                      <Select
                        placeholder="คำนาม"
                        allowClear
                      >
                        <Option value="mr">นาย</Option>
                        <Option value="mrs">นาง</Option>
                        <Option value="miss">นางสาว</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name="numberPhone" label="เบอร์โทรติดต่อ" rules={[{ required: true }]}>
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name="_id" label="ชื่อ" hidden={true}>
                      <Input />
                    </Form.Item>
                    <Form.Item name="firstname" label="ชื่อ" rules={[{ required: true }]}>
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name="lastname" label="นามสกุล" rules={[{ required: true }]}>
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name="gender" label="เพศ" rules={[{ required: true }]}>
                      <Select
                        placeholder="กรุณาเลือกเพศ"
                        onChange={onGenderChange}
                        allowClear
                      >
                        <Option value="male">ชาย</Option>
                        <Option value="female">หญิง</Option>
                        <Option value="other">อื่นๆ</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name="birthday" label="วันเกิด" {...config}>
                      <DatePicker />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name="address" label="ที่อยู่" rules={[{ required: true }]}>
                      <TextArea autoSize={{ minRows: 3, maxRows: 5 }} />
                    </Form.Item>
                  </Col>
                  <Col span={12}></Col>
                  <Divider />
                  <Col span={12}>
                    <Form.Item name="jobStartDate" label="วันเริ่มงาน" {...config}>
                      <DatePicker />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name="jobEndDate" label="สิ้นสุดงาน">
                      <DatePicker />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name="branch" label="สาขา" rules={[{ required: true }]}>
                      <Select
                        placeholder="กรุณาสาขา"
                        allowClear
                      >
                        {branch.map((item: any, index: number) => <Option key={index} value={item._id}>{item.nameTH}</Option>)}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>



                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, currentValues) => prevValues.gender !== currentValues.gender}
                >
                  {({ getFieldValue }) =>
                    getFieldValue('gender') === 'other' ? (
                      <Form.Item name="customizeGender" label="Customize Gender" rules={[{ required: true }]}>
                        <Input />
                      </Form.Item>
                    ) : null
                  }
                </Form.Item>
                <Form.Item {...tailLayout}>
                  <Button type="primary" htmlType="submit">
                    {mode === 'add' ? 'เพิ่มข้อมูล' : 'แก้ไขข้อมูล'}
                  </Button>
                  <Button htmlType="button" onClick={onReset}>
                    Reset
                  </Button>
                </Form.Item>
              </Form>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};
export default EmployeeCreatePage;
