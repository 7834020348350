
const baseApi = "https://api.leephonemoblie.net"
// const baseApi = "http://localhost:3000"
const env: any = (env) => {
    console.log(env)
    if (env === "production" || env === "master")
        return {
            baseApi: "/",
        }
    if (env === "develop" || env === "development" || env === "test")
        return {
            baseApi: "/",
            branch: baseApi + "/branch",
            company: baseApi + "/company",
            employee: baseApi + "/employee",
            phone: baseApi + "/phone",
            logs: baseApi + "/logs",
            setting: baseApi + "/setting",
        }


}
export default env(process.env.REACT_APP_DEPLOY_MENT)