import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
  SubMenu,
} from "react-pro-sidebar";
import Icon from "@mdi/react";
import {
  mdiArrowLeft,
  mdiLogout,
  mdiCircleMedium,
} from "@mdi/js";
interface Props {
  toggled: boolean;
  handleToggleSidebar: (toggled: boolean) => void;
}

const SettingSideBar: React.FC<Props> = ({ toggled, handleToggleSidebar }) => {
  const location = useLocation();
  const [currentPath, setCurrentPath] = useState<string>("");

  const settingMenuItems = [
    {
      name: "PERSONNEL",
      subMenu: [
        {
          name: "USERS GROUPS",
          to: "/setting/usersgroups",
          icon: mdiCircleMedium,
        },
        {
          name: "PERSONNEL",
          to: "/setting/personnel",
          icon: mdiCircleMedium,
        },
      ],
    },

    {
      name: "PRODUCT & SERVICE",
      subMenu: [
        {
          name: "CATEGORY",
          to: "/setting/service/category",
          icon: mdiCircleMedium,
        },

        {
          name: "TREATMENT",
          to: "/setting/service/treatment",
          icon: mdiCircleMedium,
        },
        {
          name: "PROMOTION",
          to: "/setting/service/promotion",
          icon: mdiCircleMedium,
        },
        {
          name: "GIFT VOUCHER",
          to: "/setting/service/giftvoucher",
          icon: mdiCircleMedium,
        },
        {
          name: "BARTHER (VAT)",
          to: "/setting/service/barthervat",
          icon: mdiCircleMedium,
        },
      ],
    },
    {
      name: "JOB",
      subMenu: [
        {
          name: "JOB TITLE",
          to: "/setting/job/title",
          icon: mdiCircleMedium,
        },
        {
          name: "JOB GROUP",
          to: "/setting/job/group",
          icon: mdiCircleMedium,
        },
      ],
    },
    {
      name: "AGENCY",
      subMenu: [
        {
          name: "AGENCY TYPE",
          to: "/setting/agency/type",
          icon: mdiCircleMedium,
        },
        {
          name: "AGENCY LIST",
          to: "/setting/agency/list",
          icon: mdiCircleMedium,
        },
      ],
    },
    {
      name: "STOCK",
      subMenu: [
        {
          name: "STOCK CATEGORY",
          to: "/setting/stock/category",
          icon: mdiCircleMedium,
        },
        {
          name: "CREATE STOCK",
          to: "/setting/stock/createStock",
          icon: mdiCircleMedium,
        },
        {
          name: "ADD STOCK",
          to: "/setting/stock/addStock",
          icon: mdiCircleMedium,
        },
        {
          name: "REQUEST STOCK",
          to: "/setting/stock/requestStock",
          icon: mdiCircleMedium,
        },
      ],
    },
  ];

  const isActive = (linkTo: string) => {
    // menuItem.to === currentPath

    //  abcdefzzz              de
    // /usedcourse/SO-TestB   /usedcourse
    if (currentPath.startsWith(linkTo)) {
      return true;
    }

    return false;
  };

  useEffect(() => {
    let path = location.pathname;
    // console.log("path =>", path);

    if (path.endsWith("/")) {
      path = path.slice(0, path.length - 1);
    }

    // console.log("path after =>", path);

    setCurrentPath(path);
  }, [location.pathname]);

  return (
    <ProSidebar
      toggled={toggled}
      breakPoint="xl"
      onToggle={handleToggleSidebar}
    >
      <SidebarHeader>
        <div className="logo">
          <img src={''} alt="Daol" />
        </div>
      </SidebarHeader>

      <SidebarContent>
        <Menu popperArrow>
          <MenuItem
            className={`menu-title ${
              isActive("/setting/dashboard") ? "active" : ""
            }`}
          >
            DASHBOARD & REPORT
            <Link to="/setting/dashboard" />
          </MenuItem>
          <MenuItem
            className={`menu-title ${
              isActive("/setting/systemlog") ? "active" : ""
            }`}
          >
            SYSTEM LOG
            <Link to="/setting/systemlog" />
          </MenuItem>
          <MenuItem
            className={`menu-title ${
              isActive("/setting/viplist") ? "active" : ""
            }`}
          >
            VIP LIST
            <Link to="/setting/viplist" />
          </MenuItem>
          {settingMenuItems.map((menuItem, index) => (
            <SubMenu
              className="menu-title"
              title={menuItem.name}
              key={index}
              defaultOpen
            >
              {menuItem.subMenu.map((subMenuItem, index) => (
                <MenuItem
                  className={`menu-title ${
                    isActive(subMenuItem.to) ? "active" : ""
                  }`}
                  icon={<Icon path={mdiCircleMedium} size="20" />}
                  key={index}
                >
                  {subMenuItem.name}
                  <Link to={subMenuItem.to} />
                </MenuItem>
              ))}
            </SubMenu>
          ))}
          <MenuItem
            className={`menu-title ${
              isActive("/setting/branches") ? "active" : ""
            }`}
          >
            BRANCHES
            <Link to="/setting/branches" />
          </MenuItem>
          <MenuItem
            className={`menu-title ${
              isActive("/setting/medicalequipment") ? "active" : ""
            }`}
          >
            <span style={{ textTransform: "uppercase" }}>
              Medical Equipment
            </span>
            <Link to="/setting/medicalequipment" />
          </MenuItem>
        </Menu>
      </SidebarContent>

      <SidebarFooter>
        <Menu>
          <MenuItem
            className="menu-title"
            icon={<Icon path={mdiArrowLeft} size="20" color="#AEAEAE" />}
          >
            Return To Main Menu
            <Link to="/dashboard" />
          </MenuItem>
          <MenuItem
            className="menu-title"
            icon={<Icon path={mdiLogout} size="20" color="#AEAEAE" />}
          >
            Logout
            <Link to="/logout" />
          </MenuItem>
        </Menu>
      </SidebarFooter>
    </ProSidebar>
  );
};

export default SettingSideBar;
