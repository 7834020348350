import { createReducer } from "@reduxjs/toolkit";
import { setUser } from "../action/user";

const user = {
  Code : "",
  DepartmentCode : "",
  DepartmentId: "number",
  DepartmentName : "",
  FullName  : "",
  FullNameEn  : ""
};

export const userReducer = createReducer(
  { ...user },
  {
    [setUser.type]: (state, action) => ({
      ...state,
      ...action.payload 
    })
  }
);
