
import styled from 'styled-components';
interface Props {
  noHeader?: boolean;
  firstPage?: boolean;
  btnBack?: boolean;
  title?: string;
}
const MainFooter: React.FC<Props> = ({
  children,
  noHeader = true,
  firstPage = true,
  btnBack = false,
  title = "",
}) => {

  const Footer = styled.div`
  padding: 1rem;
  margin-top: 2rem;
  background: #fdf004;
  color: #000;
  font-weight: bold;
  text-align: center;
  `


  return (
    <Footer>
      <div className="container">
        <div className="row">
          <div className="col-12">
          Lee Phone © 2022
          </div>
        </div>
      </div>
    </Footer>
  );
};

export default MainFooter;
