import Routes from "./routers/routes";
import "./assets/styles/App.scss";
import "@progress/kendo-theme-default/dist/all.css";
import { axios, setBearerToken } from "./axios";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./App.css";

const baseURL = process.env.REACT_APP_BASE_URL || "https://api.leephonemoblie.net/";
// axios.defaults.baseURL = "http://localhost:3000/";
axios.defaults.baseURL = baseURL;


function App(props: any) {
  const dispatch = useDispatch();
  const { loader } = useSelector((state: any) => state);

  useEffect(() => {
    let token: string | null | any = localStorage.getItem("token");
    setBearerToken(token);
    if (token?.length > 0 && !loader.isFetchedUser) {
      // isJumapp(token)
    } else {
      dispatch({
        type: "isFetchedUser",
        payload: true,
      });
    }
  }, [loader.isFetchedUser, dispatch]);

  return <Routes />;
}
export default App;
