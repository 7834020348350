import HeaderBar from "../../components/header/headerBar";
import Button from "../../components/button/Button";
import { SearchField } from "../../components/textfield";
import { useHistory } from "react-router";
import { Modal, Table, Card, Radio, Row, Col, Descriptions, Tag } from "antd";
import type { ColumnsType } from 'antd/es/table';
import { useEffect, useState } from "react";
import environment from "../../config/environment";
import axios from "axios";

const TablePage = () => {
  const { confirm } = Modal;
  const history = useHistory();
  const [members, setMembers] = useState<any>([])
  const [data, setData] = useState<any>([])
  const columns: ColumnsType<any> = [
    Table.EXPAND_COLUMN,
    {
      title: 'ลำดับ',
      dataIndex: 'key',
      key: 'key',
      align: "center" as "center",
      width: "10%",
      render: (text, object, index) => <>{index + 1}</>,
    },
    {
      title: 'ชื่อ-นามสกุล',
      dataIndex: 'members',
      key: 'members',
      width: "50%",
      render: (item, record) => {
        return (<>
          <Row gutter={[24, 24]}>
            <Col span={24}>{item?.firstName} {item?.lastName}</Col>
            <Col span={24}>
              <Tag color="gold">บริษัท : {record?.company?.nameTH}</Tag>
              <Tag color="cyan">สาขา : {record?.branch?.nameTH}</Tag>
            </Col>
          </Row>

        </>)
      }
    },
    {
      title: 'สถานะ',
      dataIndex: 'status',
      align: "center" as "center",
      key: 'status',
      width: "10%",
      render: (text, record) => {
        return {
          props: {
            style: { background: text === 'contract' ? '#0D9D00' : (text === 'matured' ? '#00819D' : '#9D0000'), color: "#ffffff" }
          }, children: <>{text === 'contract' ? 'ปกติ' : (text === 'matured' ? 'ครบชำระ' : 'ค้างชำระ')}</>
        }
      }
    },
    {
      title: '',
      key: '_id',
      dataIndex: '_id',
      align: "center" as "center",
      width: "30%",
      render: (_) => (
        <Row gutter={16}>
          <Col><Button title="ดูรายละเอียด / แก้ไขข้อมูล" onClick={() => history.push('/member/' + _ + '/detail')} color="#0D9D00"></Button></Col>
          <Col><Button title={'ที่อยู่เครื่องล่าสุด'} color="#9D0000" width={150} onClick={() => history.push('/member/' + _ + '/position')}></Button></Col>
        </Row>
      ),
    }
  ];

  useEffect(() => {
    fetchMembers();
  }, []);
  const fetchMembers = () => {
    axios.get(environment.phone)
      .then(async (res: any) => {
        if (res) {
          setMembers(res.data.data);
        }
      })
  }
  function showDeleteConfirm(id: string, name: any) {
    confirm({
      title: "",
      content: (
        <>
          <div>{`ต้องการลบ ${name} ออกหรือไม่ ?`}</div>
          <div>{`Are you sure delete ${name} from list?`}</div>
        </>
      ),
      // content: "Some descriptions",
      autoFocusButton: null,
      okText: "ตกลง / Submit",
      okType: "danger",
      cancelText: "ยกเลิก / Cancel",
      onOk() {
        let index = data.findIndex((item: any) => item.key === id);
        console.log(data.findIndex((item: any) => item.key === id))
        if (index > -1) {
          data.splice(index, 1);
          setData([...data]);
        }
        // axios.delete(`/${id}`).then((res) => {
        //   let index = data.findIndex((item: any) => item._id === id)
        //   let promotion = data;
        //   promotion.splice(index, 1);
        // });
      },
      width: "fit-content"
    });
  };
  return (
    <div>
      <HeaderBar title="รายชื่อลูกค้า" />
      <div style={{ padding: "10px 40px" }}>
        <SearchField
          placeholder="ค้นหาชื่อลูกค้า"
          color="#fff"
        />
        <div style={{ marginTop: "20px" }}>

          <div className="site-card-border-less-wrapper">
            <Row gutter={16}>
              <Col span={8}>
                <Card title="สถานะ / Status" bordered={false}>
                  <Radio.Group defaultValue="a">
                    <Radio.Button value="a">ทั้งหมด</Radio.Button>
                    <Radio.Button value="b">ปกติ</Radio.Button>
                    <Radio.Button value="c">ค้างชำระ</Radio.Button>
                    <Radio.Button value="d">ครบชำระ</Radio.Button>
                  </Radio.Group>
                </Card>
              </Col>
              <Col span={8}>

              </Col>
              <Col span={8}>
              </Col>
            </Row>

          </div>
        </div>
        <div style={{ marginTop: "20px" }}>
          <Table columns={columns} dataSource={members}
            rowKey={record => record?._id}
            expandable={{
              expandedRowRender: (record) => <>
                <Descriptions
                  title="เมนูเสริม"
                  layout="vertical"
                  bordered
                  column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
                >
                  <Descriptions.Item label="รุ่นโทรศัพร์มือถือ">{record?.moblieModel}</Descriptions.Item>
                  <Descriptions.Item label={<Button title="พิมพ์ใบเสร็จ" onClick={() => history.push('/member/' + record?._id + '/receipt')} color="#0D9D00"></Button>}> </Descriptions.Item>
                  <Descriptions.Item label={<Button title="ลบข้อมูล" onClick={() => showDeleteConfirm(record?._id, record)} color="#9d0000"></Button>}>ลบข้อมูลกรุณาตัดสินใจให้ดีก่อนเนื่องถ้าลบไปแล้วไม่สามารถดึงข้อมุลกลับมาได้อีก</Descriptions.Item>
                </Descriptions>
              </>
            }} />
        </div>
      </div>
    </div>
  );
};
export default TablePage;
