import { useEffect } from "react";
import { Skeleton } from 'antd';
import "./index.scss";

const LoginPage = (props: any) => {
  useEffect(() => {}, []);

  return (
    <div className="login">
      <div className="container text-center">
        <img className="logo" src={''} alt="Daol" />
        <div className="box">
          <div className="box-contant">
            <label className="label">กำลังโหลดข้อมูล</label>
            <p className="text-gray text-loading">กรุณารอสักครู่...</p>
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </div>
        </div>
      </div>
    </div>
  );
};
export default LoginPage;
