import { Button } from 'antd';
import React from 'react';
import { useSelector } from "react-redux";
import HeaderBar from "../../components/header/headerBar";
import { createRef } from "react";
import styled from 'styled-components'
import { useReactToPrint } from "react-to-print";
const PrintPage: React.FC = () => {
  const { user } = useSelector((state: any) => state);
  const refPdf = createRef<HTMLIFrameElement>()
  const handlePrint = useReactToPrint({
    content: () => refPdf.current,
    pageStyle: "@page { size: A4 portrait; }"
  });
  const PrintCss = styled.div`
    .text-right{
        text-align: right;
    }
    .font { 
        font-family: Sarabun;
        font-size: 13px !important;
    }
    .page-print  {
        // width: 21cm;
        min-height: 29.7cm;
        table {
            font-size: 13px !important;
            td{ 
                font-size: 13px !important;
            }
        }
    }
    p {
        font-size: 13px !important;
    }
    .report-info {
        padding: 15px 15px;
    }
    @page {
        size: A4 portrait;
        margin: 0;
    }
    @media print {
        p {
            font-size: 13px !important;
        }
        .page-print {
            margin: 0;
            border: initial;
            border-radius: initial;
            width: initial;
            min-height: initial;
            box-shadow: initial;
            background: initial;
            page-break-after: always;
            zoom: 80%
            // transform: scale(0.5);
        }
        .report-header{
            .logo {
                margin-bottom: 1rem;
            }
        }
        .report-info {
            padding: 15px 15px;
        }
        .report-body {
            padding: 15px 15px;
            table {
                font-size: 13px !important;
                td{ 
                    font-size: 13px !important;
                }
            }
        }
    }
    .b-r-0 {
        border-right: 0 !important;
    }
    .b-l-0 {
        border-left: 0 !important;
    }
    .b-b {
        border-bottom: 1px solid rgb(91,174,169) !important;
    }
  `
  return <>
    <HeaderBar title="Print Demo" btnBack={false} />
    <div style={{ padding: "10px 40px" }}>
      <div className="create-page">
        <div className="title-header">
          <h3>Print Page Demo</h3>
        </div>
        <div className="row">
          <Button onClick={() => handlePrint()}>Print PDF</Button>
          <div className='col-12'>
            <PrintCss
              ref={refPdf}
              style={{
                padding: '1rem',
                background: '#ffff',
                borderRadius: '1rem'
              }}>
              <div style={{
                border: ' 1px solid',
                padding: '1rem',
                borderRadius: '1rem'

              }}>
                <p>ชื่อ {user.FullName}</p>
                <p>DepartmentCode {user.DepartmentCode}</p>
              </div>
            </PrintCss>

          </div>

        </div>
      </div>
    </div >
  </>;
};

export default PrintPage;