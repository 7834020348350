import HeaderBar from "../../components/header/headerBar";
import { Modal, Card, Row, Col, Button, Form, Input, DatePicker, notification } from "antd";
import { useEffect, useState } from "react";
import environment from "../../config/environment";
import axios from "axios";
import { useHistory, useParams } from "react-router";
import moment from 'moment';
const { TextArea } = Input;
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

interface Prop {
  mode: String;
  children?: React.ReactNode;
}

const MemberDetailPage: React.FC<Prop> = ({ mode }) => {
  const { id } = useParams<{ id: string; }>();
  const history = useHistory();
  const [form] = Form.useForm();
  const [status, setStatus] = useState("");
  const [phone, setPhone] = useState<any>({});
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [value, setValue] = useState('');
  const handleOk = () => {
    setConfirmLoading(true);
    axios.post(`${environment.phone}/${id}/notification`, {
      message : value
    }).then(res => {
      const args = {
        message: 'Notification',
        description: 'ทำการส่งความความเรียบร้อยแล้ว!',
        duration: 0,
      };
      notification.open(args);
      setOpen(false);
      setValue('');
      setConfirmLoading(false);
    });
  };
  const handleCancel = () => {
    console.log('Clicked cancel button');
    setOpen(false);
  };
  const onFinish = (values: any) => {
    console.log(values)
    axios.post(`${environment.phone}/${id}/detail`, values).then(res => {
      const args = {
        message: 'Notification',
        description: mode === 'add' ? '' : 'แก้ไขข้อมูลสำเร็จแล้ว',
        duration: 0,
      };
      notification.open(args);
      history.push("/member")
    });
  };

  const onReset = () => {
    form.resetFields();
  };

  useEffect(() => {
    if (id !== null && id !== "") {
      getEmployee()
    }
  }, [id])
  const setAppProtect = () => {
    axios.post(`${environment.phone}/${id}/appProtect`, { _id: phone?._id, locked: !phone?.appProtect }).then(res => {
      getEmployee();
    });
  }
  const setAppLocked = () => {
    axios.post(`${environment.phone}/${id}/appLocked`, { _id: phone?._id, locked: !phone?.appLocked }).then(res => {
      getEmployee();
    });
  }

  const getEmployee = () => {
    axios.get(`${environment.phone}/${id}/detail`).then(res => {
      let { data } = res?.data;
      setStatus(data?.status);
      setPhone(data);
      form.setFieldsValue({
        _id: id,
        firstname: data?.members?.firstName,
        lastname: data?.members?.lastName,
        address: data?.members?.address,
        contract: data?.members?.contract,
        leasingNumber:  data?.leasingNumber === "" ? id : data?.leasingNumber,
        moblieModel: data?.moblieModel,
        paymentDeadline: moment(data?.paymentDeadline),
        installment: data?.installment,
        installments: data?.installments,
        moneyPaid: data?.moneyPaid,
        numberPaid: data?.numberPaid,
        minimumInterest: data?.minimumInterest
      });
    });
  }
  const colorBtn = status === 'contract' ? '#0D9D00' : (status === 'matured' ? '#00819D' : '#9D0000');
  return (
    <div>
      <HeaderBar title={mode === 'add' ? 'เพิ่มพนักงาน' : 'รายละเอียดข้อมูล'} btnBack={true} />
      <div style={{ padding: "10px 40px" }}>
        <Row gutter={16} justify="center">
          <Col span={16}>
            <Card title="" bordered={false}>
              <Form
                {...layout}
                form={form}
                name="control-hooks"
                onFinish={onFinish}
              >
                <Row gutter={24}>
                  <Col span={12}>
                    <Form.Item name="_id" label="ชื่อ" hidden={true}>
                      <Input />
                    </Form.Item>
                    <Form.Item name="firstname" label="ชื่อ" rules={[{ required: true }]}>
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name="lastname" label="นามสกุล" rules={[{ required: true }]}>
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name="address" label="ที่อยู่" rules={[{ required: true }]}>
                      <Input.TextArea />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name="contract" label="เบอร์โทรติดต่อ" rules={[{ required: true }]}>
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name="moblieModel" label="รุ่นโทรศัพร์มือถือ">
                      <Input disabled={true} />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name="leasingNumber" label="เลขสัญญาเช่าซื้อ">
                      <Input disabled={false} />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name="paymentDeadline" label="กำหนดชำระ">
                      <DatePicker />
                    </Form.Item>
                  </Col>
                  <Col span={12}></Col>
                  <Col span={12}>
                    <Form.Item name="installment" label="จำนวนเงินที่ต้องชำระต่องวด" rules={[{ required: true }]}>
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name="installments" label="จำนวนงวดที่ต้องชำระ" rules={[{ required: true }]}>
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name="numberPaid" label="จำนวนงวดที่ชำระแล้ว" rules={[{ required: true }]}>
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name="moneyPaid" label="จำนวนเงินที่ชำระแล้ว" rules={[{ required: true }]}>
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name="minimumInterest" label="ดอกเบี้ยต่อปี(%)" rules={[{ required: true }]}>
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, currentValues) => prevValues.gender !== currentValues.gender}
                >
                  {({ getFieldValue }) =>
                    getFieldValue('gender') === 'other' ? (
                      <Form.Item name="customizeGender" label="Customize Gender" rules={[{ required: true }]}>
                        <Input />
                      </Form.Item>
                    ) : null
                  }
                </Form.Item>
                <Form.Item {...tailLayout}>
                  <Button type="primary" htmlType="submit">
                    {mode === 'add' ? 'เพิ่มข้อมูล' : 'แก้ไขข้อมูล'}
                  </Button>
                  <Button htmlType="button" onClick={onReset}>
                    Reset
                  </Button>
                </Form.Item>
              </Form>
            </Card>
          </Col>
          <Col span={6}>
            <Card title="สถานะ" bordered={false}>
              <Button type="primary" style={{
                backgroundColor: colorBtn,
                border: `1px solid ${colorBtn}`
              }} shape="round" size="large" block>
                {status === 'contract' ? 'ปกติ' : (status === 'matured' ? 'ครบชำระ' : 'ค้างชำระ')}
              </Button>
            </Card>
            <Card title="ส่งข้อความ Notification" bordered={false} style={{ marginTop: "1rem" }}>
              <Button type="primary" shape="round" size="large" block onClick={() => setOpen(true)}>
                ส่งข้อความ
              </Button>
              <Modal
                title="กรุณาใส่ข้อความที่ต้องการส่ง"
                open={open}
                onOk={handleOk}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
              >
                <TextArea placeholder="......................" value={value}
                  onChange={(e) => setValue(e.target.value)} autoSize />
              </Modal>
            </Card>
            <Card title="ระบบป้องการถอนติดตั้ง" bordered={false} style={{ marginTop: "1rem" }}>
              <Button type="primary" style={{
                backgroundColor: colorBtn,
                border: `1px solid ${colorBtn}`
              }} shape="round" size="large" block onClick={() => setAppProtect()}>
                {phone?.appProtect ? 'ON' : 'OFF'}
              </Button>
            </Card>
            <Card title={`สถานะเครื่อง (${phone?.appLocked ? 'Locked' : 'UnLocked'})`} bordered={false} style={{ marginTop: "1rem" }}>
              {
                phone?.appLocked === false &&
                <Button onClick={() => setAppLocked()} type="primary" danger shape="round" size="large" block>
                  สั่งล็อกเครื่อง
                </Button>
              }
              {
                phone?.appLocked === true &&
                <Button onClick={() => setAppLocked()} style={{ marginTop: "0.5rem" }} type="primary" shape="round" size="large" block>
                  ปลดล็อกเครื่อง
                </Button>
              }
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};
export default MemberDetailPage;
