import { Select, Button, Radio, Row, Col } from 'antd';
import React from 'react';
import axios from "axios";
import moment from "moment";
import 'moment/locale/th';
import HeaderBar from "../../components/header/headerBar";
import { useEffect, useState, createRef } from "react";
import { useParams } from "react-router";
import environment from "../../config/environment";
import styled from 'styled-components'
import { useReactToPrint } from "react-to-print";
import type { RadioChangeEvent } from 'antd';
const ReceiptPage: React.FC = () => {
    moment.locale('th');
    const { id } = useParams<{ id: string; }>();
    const [value, setValue] = useState('');

    const refPdf = createRef<HTMLIFrameElement>()
    const [data, setData] = useState<any>();
    const [userReceipt, setUserReceipt] = useState<any>();
    const [company, setCompany] = useState<any>([]);
    const [employee, setEmployee] = useState<any>([]);
    const options = [
        { label: 'บริษัทฯ', value: '1' },
        { label: 'พนักงาน', value: '2' }
    ];
    const onChange = ({ target: { value } }: RadioChangeEvent) => {
        console.log('radio3 checked', value);
        setValue(value);
    };
    const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let index
        switch (value) {
            case '1':
                index = company.findIndex(i => i?._id === event)
                setUserReceipt({
                    name: company[index]?.nameTH,
                    address: company[index]?.address,
                    numberPhone: company[index]?.numberPhone
                })
                break;
            case '2':
                index = employee.findIndex(i => i?._id === event)
                let titleName = employee[index]?.titleName
                if (titleName === 'mr') {
                    titleName = 'นาย'
                } else if (titleName === 'mrs') {
                    titleName = 'นาง'
                } else {
                    titleName = 'นางสาว'
                }
                setUserReceipt({
                    name: `${titleName} ${employee[index]?.firstname} ${employee[index]?.lastname} `,
                    address: employee[index]?.address,
                    numberPhone: employee[index]?.numberPhone
                })
                break;
            default:
                break;
        }
        console.log(event)
    };

    const handlePrint = useReactToPrint({
        content: () => refPdf.current,
        pageStyle: "@page { size: A4 portrait; }"
    });
    const PrintCss = styled.div`
    .text-right{
        text-align: right;
    }
    .font { 
        font-family: Sarabun;
        font-size: 13px !important;
    }
    .page-print  {
        // width: 21cm;
        min-height: 29.7cm;
        table {
            font-size: 13px !important;
            td{ 
                font-size: 13px !important;
            }
        }
    }
    p {
        font-size: 13px !important;
    }
    .report-info {
        padding: 15px 15px;
    }
    @page {
        size: A4 portrait;
        margin: 0;
    }
    @media print {
        p {
            font-size: 13px !important;
        }
        .page-print {
            margin: 0;
            border: initial;
            border-radius: initial;
            width: initial;
            min-height: initial;
            box-shadow: initial;
            background: initial;
            page-break-after: always;
            zoom: 80%
            // transform: scale(0.5);
        }
        .report-header{
            .logo {
                margin-bottom: 1rem;
            }
        }
        .report-info {
            padding: 15px 15px;
        }
        .report-body {
            padding: 15px 15px;
            table {
                font-size: 13px !important;
                td{ 
                    font-size: 13px !important;
                }
            }
        }
    }
    .b-r-0 {
        border-right: 0 !important;
    }
    .b-l-0 {
        border-left: 0 !important;
    }
    .b-b {
        border-bottom: 1px solid rgb(91,174,169) !important;
    }
    tr{
      padding: 0.5rem;
      td{
        padding: 0.3rem;
      }
    }
    `

    const getCompany = () => {
        axios.get(environment.company).then(res => {
            let { data } = res?.data;
            setCompany([...data])
        });
    }
    const getEmployee = () => {
        axios.get(environment.employee).then(res => {
            let { data } = res?.data;
            setEmployee([...data])
        });
    }
    useEffect(() => {
        const getCustomer = () => {
            axios.get(`${environment.phone}/${id}/detail`).then(res => {
                let { data } = res?.data;
                setData(data)
                console.log(data)
            });
        }
        if (id !== null && id !== "") {
            getCustomer()
            getCompany()
            getEmployee()
        }
    }, [id])
    return <>
        <HeaderBar title="ใบเสร็จรับเงิน" btnBack={true} />
        <div style={{ padding: "10px 40px" }}>
            <div className="create-page">
                <div className="title-header">
                    <h3>ใบเสร็จรับเงิน</h3>
                </div>

                <Row gutter={16}>
                    <Col span={12}>เลือกผู้รับเงิน <br />
                        <Radio.Group options={options} onChange={onChange} value={value} />
                        {value === '1' &&
                            <Select
                                showSearch
                                style={{ width: 300 }}
                                placeholder="กรุณาเลือกชื่อบริษัทที่รับเงิน"
                                optionFilterProp="children"
                                filterOption={(input: any, option: any) => (option?.label ?? '').includes(input)}
                                filterSort={(optionA: any, optionB: any) =>
                                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                }
                                onChange={onNameChange}
                                options={company.map((i, k) => {
                                    return { value: i?._id, label: i?.nameTH }
                                })}
                            />
                        }
                        {value === '2' &&
                            <Select
                                showSearch
                                style={{ width: 300 }}
                                placeholder="กรุณาเลือกชื่อพนักงาน"
                                optionFilterProp="children"
                                filterOption={(input: any, option: any) => (option?.label ?? '').includes(input)}
                                filterSort={(optionA: any, optionB: any) =>
                                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                }
                                onChange={onNameChange}
                                options={employee.map((i, k) => {
                                    return { value: i?._id, label: `${i?.firstname} ${i?.lastname}` }
                                })}
                            />
                        }
                    </Col>
                    <Col><Button onClick={() => handlePrint()}>Print PDF</Button></Col>
                </Row>
                <hr />
                <div className="row">
                    <div className='col-12'>
                        <PrintCss
                            ref={refPdf}
                            style={{
                                padding: '1rem',
                                background: '#ffff',
                                borderRadius: '1rem'
                            }}>
                            <div style={{
                                padding: '1rem',
                                borderRadius: '1rem'

                            }}>
                                <Row gutter={16}>
                                    <Col span={12}>
                                        <p>{userReceipt?.name}</p>
                                        <p>ที่อยู่ {userReceipt?.address}</p>
                                        <p>เบอร์โทร {userReceipt?.numberPhone}</p>
                                    </Col>
                                    <Col span={12}>
                                        <p>วันที่ {moment().format('LL')}</p>
                                    </Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col span={12}>
                                    </Col>
                                    <Col span={12}>
                                        <h3>*** ใบเสร็จรับเงิน ***</h3>
                                        <p>เลขสัญญา {id}</p>
                                        <p>คุณ {data?.members?.firstName} {data?.members?.lastName}</p>
                                        <p>เลขที่บ้าน {data?.members?.address}</p>
                                    </Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col span={12}>
                                        <table width={'70%'} style={{
                                            border: '1px solid',
                                            padding: '1rem'
                                        }}>
                                            <tr>
                                                <th className="text-center" colSpan={2}>หนี้คงเหลือหลังชำระ จำนวนเงิน(บาท)</th>
                                            </tr>
                                            <tr>
                                                <td>เงินต้น</td>
                                                <td>0</td>
                                            </tr>
                                            <tr>
                                                <td>ค่าทวงถาม</td>
                                                <td>0</td>
                                            </tr>
                                            <tr>
                                                <td>ค่าปรับ</td>
                                                <td>0</td>
                                            </tr>
                                            <tr>
                                                <td>ชำระมาแล้ว</td>
                                                <td>0</td>
                                            </tr>
                                            <tr>
                                                <td>คงเหลือ</td>
                                                <td>0</td>
                                            </tr>
                                        </table>
                                    </Col>
                                    <Col span={12}>
                                        <table width={'100%'}>
                                            <tr>
                                                <th>รายละเอียดชำระเงิน งวดที่2</th>
                                                <th>จำนวน (บาท)</th>
                                            </tr>
                                            <tr>
                                                <td>เงินต้น</td>
                                                <td>0</td>
                                            </tr>
                                            <tr>
                                                <td>ค่าทวงถาม</td>
                                                <td>0</td>
                                            </tr>
                                            <tr>
                                                <td>ค่าปรับ</td>
                                                <td>0</td>
                                            </tr>
                                            <tr>
                                                <td>อื่นๆ</td>
                                                <td>0</td>
                                            </tr>
                                            <tr>
                                                <td>รวมเงินที่ชำระ ()</td>
                                                <td>0</td>
                                            </tr>
                                        </table>
                                        <br /><br /><br />
                                        <Row gutter={16}>
                                            <Col span={12} className="text-center">
                                                <p>__________________________</p>
                                                <p>คุณ {data?.members?.firstName} {data?.members?.lastName}</p>
                                                <p>ผู้ชำระเงิน</p>
                                            </Col>
                                            <Col span={12} className="text-center">
                                                <p>__________________________</p>
                                                <p>{userReceipt?.name}</p>
                                                <p>ผู้รับเงิน</p>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                        </PrintCss>
                    </div>

                </div>
            </div>
        </div >
    </>;
};

export default ReceiptPage;