import { createReducer } from "@reduxjs/toolkit";
import { isFetchedUser } from "../action/loader";

const loader = {
  isFetchedUser: false
};

export const loaderReducer = createReducer(
  { ...loader },
  {
    [isFetchedUser.type]: (state, action) => ({
      ...state,
      isFetchedUser: action.payload
    }),
  }
);
