import { createReducer } from "@reduxjs/toolkit";
import { setLoader, setAlert } from "../action/auth";

const loader = {
  isLoading: false
};
const alert = {
  isVisible: false,
  message: ""
};

export const authReducer = createReducer(
  { loader, alert },
  {
    [setLoader.type]: (state, action) => ({
      ...state,
      loader: { ...action.payload }
    }),
    [setAlert.type]: (state, action) => ({
      ...state,
      alert: { ...action.payload }
    })
  }
);
