import React from "react";
import "../../assets/styles/components/textfield.scss";

interface Props {
  name?: string;
  accept?: string;
  title?: string;
  type?: string;
  placeholder?: string;
  className?: string;
  other?: boolean;
  readOnly?: boolean;
  underline?: boolean;
  value?: string;
  disabled?: boolean;
  onChanage?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleBlur? : (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const Input: React.FC<Props> = ({
  name,
  accept,
  placeholder,
  onChanage,
  handleBlur,
  title,
  type = "text",
  other = false,
  className = "",
  value = "",
  readOnly = false,
  underline = true,
  disabled,
}) => {
  let attributes = Object.assign({});
  attributes.className = `${
    other ? "input-other" : !underline ? "input-no-underline" : "input"
  } ${className || ""}`;
  return (
    <div {...attributes}>
      <div className="title-input">{title}</div>
      <input
        name={name}
        accept={accept}
        readOnly={readOnly}
        type={type}
        placeholder={placeholder}
        onChange={onChanage}
        value={value}
        onBlur={handleBlur}
        disabled={disabled}
      />
    </div>
  );
};
export default Input;
