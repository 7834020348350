import "../../assets/styles/components/button.scss";
import { Link } from "react-router-dom";
import React from "react";

interface Props {
  title?: string;
  to?: string;
  children?: object;
  width?: number;
  color?: string;
  onClick?: () => void;
}
const Button: React.FC<Props> = ({
  title,
  to = "#",
  color = "#006E9D",
  children,
  onClick,
  width = "100",
}) => {
  return (
    <Link className="none-underline" to={to}>
      <button
        className="button"
        type="button"
        onClick={onClick}
        style={{
          width: `${width}px`,
          background : color,
        }}
      >
        {children}
        <div className="title-btn">{title}</div>
      </button>
    </Link>
  );
};
export default Button;
