import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  ProSidebar,
  Menu,
  SubMenu,
  MenuItem,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
} from "react-pro-sidebar";
import Icon from "@mdi/react";
import {
  mdiViewDashboardOutline,
  mdiLogout,
  mdiCircleMedium
} from "@mdi/js";
import LOGO from "../../assets/images/logo.jpg";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";

interface Props {
  toggled: boolean;
  handleToggleSidebar: (toggled: boolean) => void;
}

const SideBarNav: React.FC<Props> = ({ toggled, handleToggleSidebar }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();

  const [currentPath, setCurrentPath] = useState<string>("");
  const menuItems = [
    {
      name: "QR",
      to: "/dashboard",
      icon: mdiViewDashboardOutline,
    },
    {
      name: "รายชื่อลูกค้า",
      to: "/member",
      icon: mdiViewDashboardOutline,
    }, {
      name: "รายชื่อพนักงาน",
      to: "/employee",
      icon: mdiViewDashboardOutline,
    },
    {
      name: "จัดการข้อมูลบริษัทฯ",
      to: "/company",
      icon: mdiViewDashboardOutline,
    },
    {
      name: "จัดการข้อมูลสาขา",
      to: "/branch",
      icon: mdiViewDashboardOutline,
    },
    {
      name: "Logs Data",
      to: "/logs",
      icon: mdiViewDashboardOutline,
    },
    // {
    //   name: "เครื่องลงทะเบียนล่าสุด",
    //   to: "/#",
    //   icon: mdiViewDashboardOutline,
    //   subMenu: [
    //     {
    //       name: "Form",
    //       to: "/form",
    //       icon: mdiViewDashboardOutline,
    //     },
    //     {
    //       name: "Form Validation 2",
    //       to: "/form2",
    //       icon: mdiViewDashboardOutline,
    //     }
    //   ],
    // },
    {
      name: "ขาดการเงิน",
      to: "/otp",
      icon: mdiViewDashboardOutline,
    },

    {
      name: "กำหนด notify",
      to: "/notify",
      icon: mdiViewDashboardOutline,
    }
  ];


  const onLogout = async () => {
    localStorage.removeItem("token");
    dispatch({
      type: "isFetchedUser",
      data: false,
    });
    history.push(`/`);
  };
  const isActive = (linkTo: string) => {
    if (currentPath.startsWith(linkTo)) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    let path = location.pathname;
    // console.log("path =>", path);

    if (path.endsWith("/")) {
      path = path.slice(0, path.length - 1);
    }

    // console.log("path after =>", path);

    setCurrentPath(path);
  }, [location.pathname]);

  return (
    <ProSidebar
      toggled={toggled}
      breakPoint="xl"
      onToggle={handleToggleSidebar}
    >
      <SidebarHeader>
        <div className="logo">
          <img src={LOGO} alt="LEE PHONE" />
        </div>
      </SidebarHeader>

      <SidebarContent>
        <Menu>
          {menuItems.map((menuItem: any, index) => {
            return (menuItem?.subMenu?.length > 0) ?
              (
                <SubMenu
                  className="menu-title"
                  title={menuItem.name}
                  key={index}
                  defaultOpen
                  icon={
                    <Icon
                      path={menuItem.icon}
                      size="20"
                      color={isActive(menuItem.to) ? "#5baea9" : "#AEAEAE"}
                    />
                  }
                >
                  {menuItem.subMenu.map((subMenuItem: any, index: number) => (
                    <MenuItem
                      className={`menu-title ${isActive(subMenuItem.to) ? "active" : ""
                        }`}
                      icon={<Icon path={mdiCircleMedium} size="20" />}
                      key={index}
                    >
                      {subMenuItem.name}
                      <Link to={subMenuItem.to} />
                    </MenuItem>
                  ))}
                </SubMenu>
              )
              :
              (
                <MenuItem
                  className={`menu-title ${isActive(menuItem.to) ? "active" : ""}`}
                  key={index}
                  icon={
                    <Icon
                      path={menuItem.icon}
                      size="20"
                      color={isActive(menuItem.to) ? "#5baea9" : "#AEAEAE"}
                    />
                  }
                >
                  {menuItem.name}
                  <Link to={menuItem.to} />
                </MenuItem>
              )
          }
          )}
        </Menu>
      </SidebarContent>

      <SidebarFooter>
        <Menu>
          {/* <MenuItem
            className="menu-title"
            icon={<Icon path={mdiDotsHorizontal} size="20" color="#AEAEAE" />}
          >
            Other
            <Link to="/other" />
          </MenuItem> */}
          <MenuItem
            className="menu-title"
            icon={<Icon path={mdiLogout} size="20" color="#AEAEAE" />}
            onClick={() => onLogout()}
          >
            Logout
          </MenuItem>
        </Menu>
      </SidebarFooter>
    </ProSidebar>
  );
};

export default SideBarNav;
